import React, { Component, lazy, useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import store from "../../utils/store";
import NavbarComponent from "../../components/navbarComponent";
import constant from "../constant";
import FreewavesBanner from "../../components/freewavesBanner";
import utils from "../utils";

const EmailView = lazy(() => import("../../components/emailWall"));
const ConfirmApplePurchase = lazy(() => import("../../components/confirmApplePurchase.js"));

const ProtectedRoute = (props) => {
  let { redirectPath, component, user, loggedIn,...routeProps } = props;
  const [freewavesGuest, setFreewavesGuest] = useState(false);
  let needEmailValidate = routeProps.emailValidate !== undefined ? routeProps.emailValidate : true;
  if(needEmailValidate){
    if(props.user.status === constant.VERIFICATION_ACCOUNT_STATUS.PENDING_STORE_RECEIPT){
      component = ConfirmApplePurchase;
    }

    if(props.user.status === constant.VERIFICATION_ACCOUNT_STATUS.PENDING_ACTIVATION){
      component = EmailView;
    }
  }
  const Component = component;
  const isAccessible = Boolean(user) && loggedIn;

  useEffect(() => {
    if( utils.existCustomCookie("freeWavesGuest") && routeProps.path === '/home'){
      setFreewavesGuest(true);
      utils.createCustomCookie("freeWavesGuest", true, 0);
    }
  }, []);

  return (
    <>
      { freewavesGuest && <FreewavesBanner />}
      <NavbarComponent />
      <Route
        {...routeProps}
        render={(props) => {
          const pathUrl = window && window.location ? window.location.pathname : "/";
          const search = window && window.location ? window.location.search : "";
          if (isAccessible) return <Component {...props} />;
          else {
            store.dispatch({
              type: "OPEN_LOGIN",
              data: {
                path: pathUrl,
                search: search
              },
            });
            return <Redirect to={{ pathname: redirectPath || "/" }} />;
          }
        }}
      />
    </>
  );
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  redirectPath: PropTypes.string,
  // component: PropTypes.oneOfType([PropTypes.shape({ render: PropTypes.func.isRequired }), PropTypes.func]),
};

const mapStateToProps = (state) => {
  return {
    loggedIn: state.status,
    user: state.user,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
