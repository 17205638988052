import constant from "../utils/constant";
import store from "../utils/store";
import utils from "../utils/utils";
import auth from "./auth";
import socket from "./socket";
import geoLocationService from "../modules/geoLocationService";
import countryReducer from "../reducers/countryReducer";
import sponsors from "../modules/sponsors";
import i18n from "../i18n/i18n";
import languageReducer from "../reducers/languageReducer";
import deviceReducer from "../reducers/deviceReducer";
// import deviceReducer from "../reducers/deviceReducer";

class Sesion {
  cookieNameAuth = constant.COOKIE_AUTH;
  cookieNameUser = constant.COOKIE_USER;
  cookieNameRefresh = constant.COOKIE_AUTH_REFRESH;
  cookieGuest = constant.COOKIE_GUEST;

  async callInitialServices() {
    //We check the characteristics of the device
    const isPwa = utils.isUsingPwa();
    const isMobile = utils.isUsingMobile(); 
    const isAppleDevice = utils.isUsingAppleDevice();

    deviceReducer.setDeviceInfo(isPwa, isMobile, (isPwa && isAppleDevice));
    // deviceReducer.setDeviceInfo(isPwa, isMobile, true); // #Test line

    let cookieCountry = utils.getCustomCookie(constant.COOKIE_COUNTRY);
    if (window.location.search) {
      let queryParams = window.location.search;
      if (queryParams.includes("country_code")) {
        queryParams = window.location.search.split("=");
        if (queryParams.length > 0) {
          const country = window.location.search.split("=")[1].toUpperCase();
          if (constant.AVAILABLE_COUNTRIES[country]) {
            cookieCountry = country;
          }
        }
      }
    }

    if (cookieCountry === null) {
      const data = await geoLocationService.getGeoLocationInfo();
      if (data && data.success === true) {
        cookieCountry = data.country_code;
      }
      await this.handleLanguage( cookieCountry, false);
      if (data && data.country_code) {
        cookieCountry = data.country_code;
      } else {
        cookieCountry = constant.AVAILABLE_COUNTRIES.DEFAULT;
      }
      utils.createCustomCookie(constant.COOKIE_COUNTRY, data.country_code, 30);
    } else {
      await this.handleLanguage( cookieCountry, true);
    }
    countryReducer.saveCountry(cookieCountry);
  }

  async checkCookie() {
    const existCookieNameAuth = utils.existCookie();
    await this.callInitialServices();

    if (existCookieNameAuth === true) {
      const cookieGuest = utils.getCookieValue(constant.COOKIE_GUEST);
      if (cookieGuest === "") {
        const cookieUser = utils.getCookieValue(constant.COOKIE_USER);
        if (cookieUser !== "") {
          const user = await auth.getUser(cookieUser);
          if(user.status == constant.STATUS.DELETED){
            console.log("SERVICE COOKIE ERROR ");
          //  console.log(err);
            await auth.logout();
          }else{
            auth.addUserAccess();
            if (user.isPremium === false && user.socialInvited === true) {
              const cookieShowedPremium = utils.existCustomCookie(constant.COOKIE_PREMIUM_USER_FREE);
              if (cookieShowedPremium === false) {
                utils.createCustomCookie(constant.COOKIE_PREMIUM_USER_FREE, true, 7);
                store.dispatch({
                  type: "SHOW_PREMIUM_TO_FREE",
                  data: true,
                });
              } else {
                store.dispatch({
                  type: "SHOW_PREMIUM_TO_FREE",
                  data: false,
                });
              }
            }
          }
        } else {
          await auth.accessGuest(cookieGuest);
          store.dispatch({
            type: "CHECK_COOKIE",
            data: true,
          });
        }
      } else {
        await auth.accessGuest(cookieGuest);
        store.dispatch({
          type: "CHECK_COOKIE",
          data: true,
        });
      }
    } else {
      await auth.createGuest();
      store.dispatch({
        type: "CHECK_COOKIE",
        data: true,
      });
    }
  }

  // To do: refactor of this function
  // lan se usa para obtener el idioma del pais del usuario, si se quiere usar el idioma actual del usuario es mejor usar el i18n.language valor del useTranslation; buscar ejemplo en archivo useHandleDirection.js
  async handleLanguage(language, overrideLanguage) {
    const storeData = store.getState();
    if (storeData.lan === undefined || storeData.lan === "" || overrideLanguage === true) {
      let userCountry = constant.AVAILABLE_COUNTRIES.UY;
      userCountry = constant.AVAILABLE_COUNTRIES[language];
      const lan = userCountry === constant.AVAILABLE_COUNTRIES.BR ? "pt" : "es";
      languageReducer.saveLan(lan);
      i18n.changeLanguage(lan);
    }
  }

}
const session = new Sesion();
export default session;
