import React from 'react'

const PopupOverlay = ({children}) => {
  return (
    <div className='popup-overlay-container'>
      {children}
    </div>
  )
}

export default PopupOverlay
