import constant from "../utils/constant";
import store from "./store";
import userService from "../modules/userService";
import { stringify } from "uuid";
import axios from "axios";

class Utils {
  goToRegister = (open, isRegister, url) => {
    store.dispatch({
      type: "OPEN_LOGIN",
      data: {
        open: open,
        isRegister: isRegister,
        url: url,
      },
    });
  };

  isUsingAppleDeviceViaUrl() {
    return window.location.hostname.toLowerCase().split('.')[0] === 'ios';
  }

  isUsingMobile() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) || this.isAppleDomain();
  }

  isUsingAppleDevice() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent) || this.isAppleDomain();
  }

  isUsingPwa() {
    return window.matchMedia('(display-mode: standalone)').matches || this.isAppleDomain();
  }

  titleCase(str) {
    try {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    } catch {
      return str;
    }
  }

  removeAccents(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async getMinMaxWaves(data, language) {
    let waves = [];
    let result = data["data"];
    let index = 0;
    Object.keys(result).forEach(function (key) {
      let value = result[key];
      let currentDate = new Date(key);
      currentDate.setDate(currentDate.getDate() + 1);
      let currentDateName = currentDate.toLocaleString(language, { weekday: "long" }) + "";
      currentDateName = currentDateName.charAt(0).toUpperCase() + currentDateName.slice(1) + "";
      let maxMin = {
        day: currentDateName,
      };
      Object.keys(value).forEach(function (key) {
        let item = value[key];
        if (maxMin["max"] === undefined) {
          maxMin["max"] = item["waveHeight"];
          maxMin["min"] = item["waveHeight"];
        }
        if (item["waveHeight"] > maxMin["max"]) {
          maxMin["max"] = item["waveHeight"];
        }
        if (item["waveHeight"] < maxMin["min"]) {
          maxMin["min"] = item["waveHeight"];
        }
      });
      waves.push(maxMin);
      index = index + 1;
    });
    return {
      ...data,
      waves: waves,
    };
  }

  getChartData(data, language, isPremium) {
    let chartData = [];
    let pronos = data["data"];
    let optimum = data["swell_optimum"];
    let favorable = data["swell_favorable"];
    let getQuality = (favorable, optimum, orientation) => this.swellQuality(favorable, optimum, orientation);
    let maxDaysForFreeUSer = constant.FORECAST_DAYS_FREE_USER - 1; // se le resta uno para usarlo como indice

    Object.keys(pronos).forEach(function (dateKey, index) {

      /* 
       Armando la fecha.
       Entrada:  2024-04-13 <- dateKey
       Salidas: "Sábado" <- currentDateName
                "13/4" <- currentDateNumber
      */

      let currentDate = new Date(dateKey);
    
      currentDate.setDate(currentDate.getDate() + 1);
      let currentDateNumber = currentDate.toLocaleString('es-ES', { day: '2-digit', month: '2-digit' });
      let currentDateName = currentDate.toLocaleString(language, { weekday: "long" }) + "";
      currentDateName = currentDateName.charAt(0).toUpperCase() + currentDateName.slice(1) + "";


      /* Armando barData, cada barData representa un dia en la grafica. */
      let barData = {
        day: currentDateName,
        date: currentDateNumber,
        dayHeights: [],
        orientations: [],
        orientationsQuality: [],
        periods: [],
        ratings: [],
        maxWave: 0,
        needBlur: false,
      };

      /* 
        TODO: 
          este metodo no es escalable, 
          buscar alguna manera de resolverlo de otra forma, 
          preferiblemente desde el backend. 
          Se esta agregando hora a hora manualmente, 
          si se le agregase una hora mas al prono hay que agregarlo en dayHeights manualmente
      */

      let pronoData = pronos[dateKey];

      /* 
        Cada elemento de dayHeights representa una barra en la grafica de la altura de la ola.
        Se usa un arreglo indexado donde queda ordenados los horarios y se puede recorrer facilmente de manera lineal.
      */
      barData.dayHeights.push( !isPremium && index > maxDaysForFreeUSer ? 1 : pronoData[constant.FORCAST_TIME.MORNING].waveHeight);
      barData.dayHeights.push( !isPremium && index > maxDaysForFreeUSer ? 0.8 : pronoData[constant.FORCAST_TIME.MIDDAY].waveHeight);
      barData.dayHeights.push( !isPremium && index > maxDaysForFreeUSer ? 0.3 : pronoData[constant.FORCAST_TIME.AFTERNOON].waveHeight);

      barData.orientations.push( !isPremium && index > maxDaysForFreeUSer ? "S" : pronoData[constant.FORCAST_TIME.MORNING].swellOrientation );
      barData.orientations.push( !isPremium && index > maxDaysForFreeUSer ? "SE" : pronoData[constant.FORCAST_TIME.MIDDAY].swellOrientation );
      barData.orientations.push( !isPremium && index > maxDaysForFreeUSer ? "SE" : pronoData[constant.FORCAST_TIME.AFTERNOON].swellOrientation );

      barData.periods.push( !isPremium && index > maxDaysForFreeUSer ? 1 : pronoData[constant.FORCAST_TIME.MORNING].swellPeriod);
      barData.periods.push( !isPremium && index > maxDaysForFreeUSer ? 1 : pronoData[constant.FORCAST_TIME.MIDDAY].swellPeriod);
      barData.periods.push( !isPremium && index > maxDaysForFreeUSer ? 1 : pronoData[constant.FORCAST_TIME.AFTERNOON].swellPeriod);
      
      barData.ratings.push( !isPremium && index > maxDaysForFreeUSer ? 1 : pronoData[constant.FORCAST_TIME.MORNING].rating);
      barData.ratings.push( !isPremium && index > maxDaysForFreeUSer ? 1 : pronoData[constant.FORCAST_TIME.MIDDAY].rating);
      barData.ratings.push( !isPremium && index > maxDaysForFreeUSer ? 1 : pronoData[constant.FORCAST_TIME.AFTERNOON].rating);
      
      barData.orientationsQuality = barData.orientations.map((orientation, index) => {
        return getQuality(favorable, optimum, orientation);
      });


      // barData.orientationsQuality = !isPremium && index > 3 ? ["optimum", "optimum", "optimum"] : //Continue on the next line
      // barData.orientations.map((orientation, index) => {
      //   return getQuality(favorable, optimum, orientation);
      // });

      if(!isPremium && index > maxDaysForFreeUSer){
        barData.needBlur = true;
      }

      chartData.push(barData);
    });
    console.log(chartData);
    return chartData;
  }

  getWindData(data, language, isPremium, t) {
    let chartData = [];
    let pronos = data["data"];
    let maxDaysForFreeUSer = constant.FORECAST_DAYS_FREE_USER - 1; // se le resta uno para usarlo como indice

    Object.keys(pronos).forEach(function (dateKey, index) {

      let currentDate = new Date(dateKey);
    
      currentDate.setDate(currentDate.getDate() + 1);
      let currentDateNumber = currentDate.toLocaleString('es-ES', { day: '2-digit', month: '2-digit' });
      let currentDateName = currentDate.toLocaleString(language, { weekday: "long" }) + "";

      if(currentDate.getDate() == new Date().getDate + 1) currentDateName = t("_TODAY");
      else currentDateName = currentDateName.charAt(0).toUpperCase() + currentDateName.slice(1) + "";


      let barData = {
        day: currentDateName,
        date: currentDateNumber,
        maxWindSpeed: 0,
        minWindSpeed: 0,
        windSpeed: [],
        windOrientation: [],
        windType: [],
        needBlur: false,
      };

      let pronoData = pronos[dateKey];

      barData.maxWindSpeed = Math.max(
        pronoData[constant.FORCAST_TIME.MORNING].windSpeed,
        pronoData[constant.FORCAST_TIME.MIDDAY].windSpeed,
        pronoData[constant.FORCAST_TIME.AFTERNOON].windSpeed,
      ); 
      barData.minWindSpeed = Math.min(
        pronoData[constant.FORCAST_TIME.MORNING].windSpeed,
        pronoData[constant.FORCAST_TIME.MIDDAY].windSpeed,
        pronoData[constant.FORCAST_TIME.AFTERNOON].windSpeed,
      ); 
      barData.windType.push( !isPremium && index > maxDaysForFreeUSer ? "cross-onshore / cruzado-maral" : pronoData[constant.FORCAST_TIME.MORNING].windType);
      barData.windType.push( !isPremium && index > maxDaysForFreeUSer ? "cross-onshore / cruzado-maral" : pronoData[constant.FORCAST_TIME.MIDDAY].windType);
      barData.windType.push( !isPremium && index > maxDaysForFreeUSer ? "cross-onshore / cruzado-maral" : pronoData[constant.FORCAST_TIME.AFTERNOON].windType);

      barData.windSpeed.push( !isPremium && index > maxDaysForFreeUSer ? 10 : pronoData[constant.FORCAST_TIME.MORNING].windSpeed);
      barData.windSpeed.push( !isPremium && index > maxDaysForFreeUSer ? 10 : pronoData[constant.FORCAST_TIME.MIDDAY].windSpeed);
      barData.windSpeed.push( !isPremium && index > maxDaysForFreeUSer ? 10 : pronoData[constant.FORCAST_TIME.AFTERNOON].windSpeed);

      barData.windOrientation.push( !isPremium && index > maxDaysForFreeUSer ? "S" : pronoData[constant.FORCAST_TIME.MORNING].windOrientation);
      barData.windOrientation.push( !isPremium && index > maxDaysForFreeUSer ? "SE" : pronoData[constant.FORCAST_TIME.MIDDAY].windOrientation);
      barData.windOrientation.push( !isPremium && index > maxDaysForFreeUSer ? "SE" : pronoData[constant.FORCAST_TIME.AFTERNOON].windOrientation);

      if(!isPremium && index > maxDaysForFreeUSer){
        barData.needBlur = true;
      }

      chartData.push(barData);
    });
    
    return chartData;
  }

  formatUrl(path) {
    path = path.replace(/ /g, "-");
    path = path.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    path = path.toLowerCase();
    return path;
  }

  getCookieValue(value) {
    let cookieResponse = "";
    const cookieResult = document.cookie.match("(^|;)\\s*" + constant.COOKIE_AUTH + "\\s*=\\s*([^;]+)")?.pop() || "";
    if (cookieResult !== "" && cookieResult !== "undefined") {
      try {
        const cookie = JSON.parse(cookieResult);
        const cookieValue = cookie[value];
        if (cookieValue !== undefined) {
          cookieResponse = cookieValue;
        }
      } catch (error) { }
    }
    return cookieResponse;
  }

  createCustomCookie(name, value, days) {
    let d = new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * days);
    document.cookie = name + "=" + value + "; expires=" + d.toGMTString() + ";SameSite=None;Secure";
  }

  existCustomCookie(cookieName) {
    let exist = false;
    const cookieResult = document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)")?.pop() || "";
    if (cookieResult !== "" && cookieResult !== "undefined") {
      exist = true;
    }
    return exist;
  }

  getCustomCookie(cookieName) {
    let exist = null;
    const cookieResult = document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)")?.pop() || "";
    if (cookieResult !== "" && cookieResult !== "undefined") {
      exist = cookieResult;
    }
    return exist;
  }

  existCookie() {
    const cookieResult = document.cookie.match("(^|;)\\s*" + constant.COOKIE_AUTH + "\\s*=\\s*([^;]+)")?.pop() || "";
    if (cookieResult !== "" && cookieResult !== "undefined") {
      return true;
    }
    return false;
  }

  blockBodyScroll(needBlock) {
    if (needBlock === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }

  orderSurfcams(cam, surfcams) {
    let result = [];
    if (surfcams["ordering"]) {
      let camProximity = surfcams["ordering"][cam["id"]];
      if (camProximity) {
        for (let j = 0; j < camProximity.length; j++) {
          const currentCam = camProximity[j];
          for (let index = 0; index < surfcams["cams"].length; index++) {
            const element = surfcams["cams"][index];
            if (currentCam === element["id"]) {
              result.push(element);
              break;
            }
          }
        }
      }
    }
    return result;
  }

  formatInterpreterText(text) {
    if (text !== undefined) {
      const formattedText = text.split(";");
      const responseText = [];
      for (let index = 0; index < formattedText.length; index++) {
        let element = formattedText[index];
        if (element.length > 1) {
          //element = element.replace("-", "");
          //element = element.replace("-", "**")
          responseText.push(element + ". ");
        }
      }
      return responseText;
    } else {
      return [];
    }
  }

  isDateAvailable(date) {
    if (date !== "" && date !== undefined) {
      var d1 = Date.parse(date);
      var d2 = Date.parse(new Date());
      return d2 > d1;
    }
    return true;
  }

  compareDate(date) {
    const currentCam = new Date();
    const endDate = new Date(date);
    let difference = endDate.getTime() - currentCam.getTime();
    let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
    return totalDays;
  }

  compareDateHours(date) {
    const currentCam = new Date();
    const endDate = new Date(date);
    let difference = Math.abs(endDate.getTime() - currentCam.getTime());
    let totalHours = Math.floor(difference / (1000 * 3600));
    return totalHours;
  }

  formateDateMonthDay(date, language, separatorText) {
    let myDate = new Date(date);
    const month = myDate.toLocaleString(language + "-US", { month: "long" });
    const day = myDate.getDate();
    return day + separatorText + month;
  }

  addScript(scriptUrl, id) {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_DLOCAL_ENDPOINT;
    script.async = true;
    document.body.appendChild(script);
  }

  calculateAmountToPay = (amount, discount) => {
    if (discount !== null && discount !== undefined) {
      parseInt(amount);
      let discountValue;
      discountValue = typeof discount === 'object' ? discount.amount : discount - 12;
      let applyDiscount = amount - (amount * discountValue) / 100;
      applyDiscount = parseFloat(applyDiscount).toFixed(2);
      return applyDiscount;
    } else {
      return amount;
    }
  };

  calculatePercentage(value, percentage) {
    if(percentage == 0) return 0;

    percentage = percentage / 100;
    return value * percentage;
  }

  calculeMonthlyAmount = (plan, discount = 0) => {
    let amount = plan.amount - discount;
    if (plan.id === constant.SUBSCRIPTION.ANNUALLY) {
      amount = utils.roundToTwo(amount / constant.MONTHS_ANNUAL_PLAN);
    } else if (plan.id === constant.SUBSCRIPTION.ESPECIAL_DAY) {
      amount = utils.roundToTwo(amount / 6);
    }

    return amount;
  }

  createSkeletonForecast = () => {
    return {
      morning: {
        swellDirection: "-",
        swellOrientation: "-",
        swellOrientation2: "-",
        swellPeriod: "-",
        swellPeriod: "-",
        warning: {},
        waveHeight: "-.--",
        windDirection: "---.--",
        windOrientation: "--",
        windSpeed: "--",
        windType: "---- / ----",
      },
      midday: {
        swellDirection: "-",
        swellOrientation: "-",
        swellOrientation2: "-",
        swellPeriod: "-",
        swellPeriod: "-",
        warning: {},
        waveHeight: "-.--",
        windDirection: "---.--",
        windOrientation: "--",
        windSpeed: "--",
        windType: "---- / ----",
      },
      afternoon: {
        swellDirection: "-",
        swellOrientation: "-",
        swellOrientation2: "-",
        swellPeriod: "-",
        swellPeriod: "-",
        warning: {},
        waveHeight: "-.--",
        windDirection: "---.--",
        windOrientation: "--",
        windSpeed: "--",
        windType: "---- / ----",
      },
    };
  };

  roundToTwo(num) {
    return Math.floor(num * 100) / 100;
  }

  formatDayToShow(userDate) {
    let dateToShow = userDate.split("-");
    dateToShow = dateToShow[2] + "/" + dateToShow[1] + "/" + dateToShow[0];
    return dateToShow;
  }

  formatDayTimeToShow(userDate, t) {
    let dateToShow = userDate.split("-");
    let dayWithTime = dateToShow[2].split(" ");
    let day = dayWithTime[0];
    let timeParts = dayWithTime[1].split(":");
    return day + "/" + dateToShow[1] + "/" + dateToShow[0] + " " + t("utils_time_at") + " " + timeParts[0] + ":" + timeParts[1] + " hs";
  }

  formatListToString(elementList) {
    let result = [];
    for (let index = 0; index < elementList.length; index++) {
      const element = elementList[index];
      result.push(element);
    }
    return result.toString();
  }

  isSpotFavorite(id, userSpots) {
    if (userSpots !== undefined && userSpots !== "" && userSpots !== null) {
      let favoritesResult = userSpots.split(",");
      for (let index = 0; index < favoritesResult.length; index++) {
        const spotId = favoritesResult[index];
        if (spotId === id) {
          return true;
        }
      }
    }
    return false;
  }

  async addFavorite(id, userSpots, isPremium) {
    let favoritesList = [];
    let res = null;
    if (userSpots !== null) {
      if (userSpots.indexOf(",") > -1) {
        favoritesList = userSpots.split(",");
      } else {
        if(userSpots !== ''){
          favoritesList.push(userSpots);
        }
      }
    }
    if (isPremium) {
      if (favoritesList.length < 6) {
        favoritesList.push(id);
        res = await userService.handleFavorites(favoritesList);
      }
    } else {
      if (favoritesList.length < 1) {
        favoritesList.push(id);
        res = await userService.handleFavorites(favoritesList);
      }
    }
    return res;
  }

  async removeFavorite(id, userSpots) {
    let favoritesList = userSpots.split(",");
    let indx = -1;
    for (let index = 0; index < favoritesList.length; index++) {
      const spotId = favoritesList[index];
      if (id == spotId) {
        indx = index;
        break;
      }
    }
    favoritesList.splice(indx, 1);
    let res = await userService.handleFavorites(favoritesList); 
    return res;
  }

  canAddMoreFavorites(userSpots, isPremium) {
    if (userSpots !== undefined && userSpots !== "" && userSpots !== null) {
      let favoritesList = userSpots.split(",");
      if (isPremium === true) {
        return favoritesList.length < 4;
      } else {
        return favoritesList.length < 1;
      }
    } else {
      return true;
    }
  }

  formatDays(data, i18n, t) {
    let result = [];
    let resultDay = null;
    let index = 0;
    Object.keys(data.data).forEach(function (key) {
      let currentDate = new Date(key);
      currentDate.setDate(currentDate.getDate() + 1);
      let currentDateName = currentDate.toLocaleString(i18n.language, { weekday: "long" });
      let currentDateValue = currentDateName + " " + currentDate.getDate();
      if (index === 0 && currentDate.getDate == new Date().getDate()) {
        resultDay = data.data[key];
        currentDateValue = t("_TODAY");
      }
      if (index === 1 && currentDate.getDate == new Date().getDate() + 1) {
        currentDateValue = t("_TOMORROW");
      }
      if(currentDate.getDate() == new Date().getDate()) resultDay = data.data[key];
      result.push(currentDateValue);
      index = index + 1;
    });
    return {
      today: resultDay,
      all: result,
    };
  }

  isValidObject(obj) {
    return obj !== null && obj !== undefined && Object.keys(obj).length > 0;
  }

  isValidDate(startDate, endDate) {
    const currentDay = new Date();
    const startDay = new Date(startDate);
    const endDay = new Date(endDate);
    return currentDay > startDay && currentDay < endDay;
  }

  handleFields(fieldValue, minimumCharacter) {
    if (fieldValue.length < minimumCharacter) {
      return false;
    } else {
      return true;
    }
  }

  convertToTime(number, secondsText, minutesText) {
    if (number < 60) {
      return `${number} ${secondsText}`;
    }
    let result = 0;
    for (let i = number; i % 60 > 0; i = i - 1) {
      result = i;
    }
    result = result - 1;

    let minutes = result / 60;
    let seconds = number - result;
    return `${minutes}:${seconds} ${minutesText}`;
  }

  fancyTimeFormat(number, hoursText, secondsText, minutesText) {
    // Hours, minutes and seconds
    var hrs = ~~(number / 3600);
    var mins = ~~((number % 3600) / 60);
    var secs = ~~number % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
      return hrs + ":" + (mins < 10 ? "0" : "") + mins + " " + hoursText;
    }
    if (mins > 0) {
      return mins + ":" + (secs < 10 ? "0" : "") + secs + " " + minutesText;
    } else {
      return secs + " " + secondsText;
    }
  }

  getCameraNumber(position) {
    return position[0].toUpperCase() + "" + position[position.length - 1];
  }

  sortMapMap(data, keyValue) {
    let sorted = {};
    const dataArray = Object.entries(data).map(([key, value]) => ({ key, ...value }));

    const objs = dataArray.sort(function (a, b) {
      if (a["totalWatched"] > b["totalWatched"]) {
        return -1;
      }
      if (a["totalWatched"] < b["totalWatched"]) {
        return 1;
      }
      return 0;
    });

    const result = {};

    for (let index = 0; index < objs.length; index++) {
      const element = objs[index];
      result[element["key"] + " "] = { ...element };

    }
    return result;
  }

  isUserInUrl(location, url) {
    if (location && location.pathname) {
      if (location.pathname.includes(url)) {
        return true;
      }
    }
    return false;
  }

  formatFavorites(multispot) {
    let result = [];
    for (let index = 0; index < multispot.length; index++) {
      const element = multispot[index]
      if (element && element.id) {
        result.push(element.id);
      }
    }
    return result;
  }

  getCameraPosition(cameraPosition) {
    return cameraPosition.replace("camera", "")
  }

  getOrientation(sptOrientation) {
    sptOrientation = Math.round(sptOrientation);
    switch (true) {
      case (sptOrientation >= 0 && sptOrientation <= 22.5) || sptOrientation > 337.5:
        return "N";
      case sptOrientation > 22.5 && sptOrientation <= 67.5:
        return "NE";
      case sptOrientation > 67.5 && sptOrientation <= 112.5:
        return "E";
      case sptOrientation > 112.5 && sptOrientation <= 157.5:
        return "SE";
      case sptOrientation > 157.5 && sptOrientation <= 202.5:
        return "S";
      case sptOrientation > 202.5 && sptOrientation <= 247.5:
        return "SO";
      case sptOrientation > 247.5 && sptOrientation <= 292.5:
        return "O";
      case sptOrientation > 292.5 && sptOrientation <= 337.5:
        return "NO";
      default:
        console.log("nada")
        return "";
    }
  }

  orderSpots(spots) {
    let result = {};
    for (let index = 0; index < spots.length; index++) {
      const spt = spots[index];
      if (result[spt['country']] === undefined) {
        result[spt['country']] = {
          recommended: [],
          all: [],
        };
      }
      if (result[spt['country']]['all'][spt['city']] === undefined) {
        result[spt['country']]['all'][spt['city']] = {
          name: spt['city'],
          values: [],
        };
      }
      result[spt['country']]['all'][spt['city']]['values'].push(spt);
      result[spt['country']]['all'][spt['city']]['values'] =
        result[spt['country']]['all'][spt['city']]['values'];

      if (spt['surfcam'] === true) {
        result[spt['country']]['recommended'].push(spt);
        result[spt['country']]['recommended'] = result[spt['country']]['recommended'];
      }
    }
    return result;
  }

  getCoordinates(spots, country) {
    let coordinatesArray = []
    if (Object.values(spots).length > 0 && spots[country] !== undefined) {
      Object.keys(spots[country]["all"]).map((key, index) => {
        spots[country]["all"][key]["values"].map((spt, index) => {
          let coordinatesSplit = spt.coordinates.split(",")
          let coordinatesObject = { position: { lat: parseFloat(coordinatesSplit[0]), lng: parseFloat(coordinatesSplit[1]) }, info: { ...spt } }
          coordinatesObject.info.orientation = this.getOrientation(coordinatesObject.info.orientation)
          coordinatesArray.push(coordinatesObject);
        })
      })
    }
    return coordinatesArray
  } parseCampaignParams(params) {
    const result = {}
    result["utm_source"] = params.get("utm_source")
    result["utm_medium"] = params.get("utm_medium")
    result["utm_id"] = params.get("utm_id")
    return result;
  }
  getDay() {
    const fecha = new Date();
    const year = fecha.getFullYear();
    const month = fecha.getMonth() + 1;
    const day = fecha.getDate();
    return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`
  }

  getYear() {
    let now = new Date();
    return now.getFullYear()
  }

  getGenericSpotLink(link) {
    let result = `/spots/country/${link}/all`;
    if (link === undefined) {
      result = `/spots/country/${constant["AVAILABLE_COUNTRIES"]["DEFAULT"]}/all`
    }
    return result
  }

  getLocalRefreshToken() {
    const refreshToken = utils.getCookieValue(constant.COOKIE_AUTH_REFRESH);
    return refreshToken;
  }

  async refreshToken() {
    let result = "";
    await axios({
      method: "post",
      url: process.env.REACT_APP_CORE_URL + "/refreshToken",
      headers: {
        Accept: "application/json",
      },
      data: {
        refresh_token: this.getLocalRefreshToken(),
      },
    })
      .then((res) => {
        result = res.data.data;
      })
      .catch(() => {
        return "";
      });
    return result;
  }

  setCookie(newToken, newRefreshToken) {
    const cookieData = {
      [constant.COOKIE_AUTH]: newToken,
      [constant.COOKIE_AUTH_REFRESH]: newRefreshToken,
      [constant.COOKIE_USER]: this.getCookieValue(constant.COOKIE_USER),
    };
    document.cookie = constant.COOKIE_AUTH + "=" + JSON.stringify(cookieData) + ";max-age=2147483647;path=/";
  }

  formatWind(text) {
    let res = text.split("/");
    return res[1].trim();
  }

  swellQuality(favorable, optimum, direction) {
    let result;

    if (!favorable && !optimum) {
      result = 'undefined';
    } else {
      result = favorable && favorable.includes(direction) ? "favorable" :
        optimum && (optimum == direction) ? "optimum" :
          "unfavorable";
    };

    return result;
  }

  formatSpotUrl(url) {
    return url.toLowerCase().replace(/\s/g, '-')
  }

  getSpotUrl(country, city, url) {
    return `/spots/${this.formatSpotUrl(country)}/${this.formatSpotUrl(city)}/${url}`
  }

  getFormattedAmount(amount) {
    const options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
      useGrouping: true,
    }

    //round up
    const firstDecimal = (Math.floor(amount * 10) % 10);
    if(firstDecimal >= 5) amount = Math.ceil(amount);

    const formattedAmount = Number(amount).toLocaleString('es-ES', options);

    return formattedAmount;
  }

  toggleScrollBar(hidden){
    document.querySelector('body').style.overflow = hidden ? 'hidden' : '';
  }

  isAppleDomain(){
    let res = false;
    constant.APPLE_DOMAINS.forEach(domain => {
      if(domain === window.location.host) res = true;
    })
    return res;
  }

  isPremiumAppleDomain(){
    let res = false;
    constant.PREMIUM_APPLE_DOMAINS.forEach(domain => {
      if(domain === window.location.host) res = true;
    })
    return res;
  }

  isAppleAccount(mail){
    let res = false;
    constant.APPLE_ACCOUNT.forEach(account => {
      if(account === mail) res = true; 
    })
    return res;
  }

  // Definir la función debounce
  debounce(func, delay) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);  // Limpia el timeout anterior
      timeout = setTimeout(() => func.apply(this, args), delay);  // Crea un nuevo timeout
    };
  }
}

const utils = new Utils();

export default utils;
