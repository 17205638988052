import React, { Component, lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import NavbarComponent from "../../components/navbarComponent";
import { connect } from "react-redux";
import constant from "../constant.js";


const EmailView = lazy(() => import("../../components/emailWall"));
const ConfirmApplePurchase = lazy(() => import("../../components/confirmApplePurchase.js"));

function PublicRoute(props) {
  let { children, component, user, loggedIn, isAuthenticated, ...rest } = props
  let needEmailValidate = rest.emailValidate !== undefined ? rest.emailValidate : true;

  if(needEmailValidate){
    if(props.user.status === constant.VERIFICATION_ACCOUNT_STATUS.PENDING_STORE_RECEIPT){
      component = ConfirmApplePurchase;
    }

    if(props.user.status === constant.VERIFICATION_ACCOUNT_STATUS.PENDING_ACTIVATION){
      component = EmailView;
    }
  }

  const Component = component;
  let routeProps = loggedIn === 'logged' ? rest : props
  return (
    <>
      <NavbarComponent />
      <Route 
      { 
        ...rest
      }
      
      render={({location}) => {
          return <Component {...props} />
      }}
       />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.status,
    user: state.user,
  };
};

export default connect(mapStateToProps)(PublicRoute);